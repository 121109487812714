import React from 'react';
import classnames from 'classnames';
import { useLostFoundContext } from '../../helpers/lostFoundContext';
import { BoxProps } from './Box.types';
import styles from './Box.module.css';
import BoxEditForm from './BoxEditForm/BoxEditForm';
import { useBoxContent } from './useBoxContent';

function Box({ box, onActivate, theme, isMoving }: BoxProps): JSX.Element {
  const { pos, data, isActive } = box;
  const { layout, isActiveBoxEdit, setIsActiveBoxEdit } = useLostFoundContext();
  const {
    content,
    cellActiveClassName,
    boxActiveClassName,
    contentActiveClassName,
    contentSizeLevel
  } = useBoxContent({
    title: data.title,
    isActive
  });
  const cellSize = isActive ? layout.activeCell.size : layout.cell.size;
  const cellStyle = {
    ...pos,
    ...cellSize,
    transform: `translate(-${cellSize.width / 2}px, -${cellSize.height / 2}px)`
  };
  const { BoxTheme } = theme;
  return (
    <div
      className={classnames(
        styles.cell,
        isActive && styles.cellActive,
        isActive && cellActiveClassName
      )}
      style={cellStyle}
      onClick={(): void => {
        !isActive && onActivate?.();
      }}
    >
      <div className={classnames(styles.box, isActive && boxActiveClassName)}>
        {isActive && isActiveBoxEdit ? (
          <BoxEditForm
            data={box.data}
            onCancel={(): void => setIsActiveBoxEdit(false)}
            onUpdate={(updatedBox): void => {
              box.updateData(updatedBox);
              setIsActiveBoxEdit(false);
            }}
            onDelete={(): void => {
              location.reload();
            }}
          />
        ) : (
          <>
            <BoxTheme
              contentClass={isActive ? contentActiveClassName : undefined}
              contentSizeLevel={contentSizeLevel}
              isActive={isActive}
              isMoving={isMoving}
              theme={theme}
            >
              {content}
            </BoxTheme>
          </>
        )}
      </div>
    </div>
  );
}

export default Box;
