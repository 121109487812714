import { TeamBgEffect, Theme } from '../types';
import GridBgTheme from './GridBgTheme/GridBgTheme';
import styles from './theme.module.css';

const theme: Theme = {
  GridBgTheme,
  classes: styles,
  themeBgEffects: {
    inEffect: TeamBgEffect.FADE_IN_TOP_DOWN
  },
  passiveBoxThemeEffects: {
    randomRotate: [-10, 10]
  },
  mediaToPreload: [
    'https://dl.dropboxusercontent.com/s/1iqiz4nempk2h2z/dive.jpg'
  ]
};

export default theme;
