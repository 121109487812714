import React, { FunctionComponent } from 'react';
import { Route, Routes } from 'react-router-dom';
import './styles.css';
import LostFound from '../scenes/LostFound/LostFound';
import Account from '../scenes/Account/Account';
import { GlobalRequestContextProvider } from '../../global/helpers/globalRequestContext';

const App: FunctionComponent = () => {
  return (
    <GlobalRequestContextProvider>
      <Routes>
        <Route path="/" element={<LostFound />} />
        <Route path="/account" element={<Account />} />
      </Routes>
    </GlobalRequestContextProvider>
  );
};

export default App;
