import React, { FunctionComponent } from 'react';
import { useGlobalRequestContext } from '../../../global/helpers/globalRequestContext';
import SignInForm from './components/SignInForm/SignInForm';
import UserInfo from './components/UserInfo/UserInfo';

const Account: FunctionComponent = () => {
  const { user } = useGlobalRequestContext();
  return user?.name ? <UserInfo user={user} /> : <SignInForm />;
};

export default Account;
