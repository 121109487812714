import { useApiGet } from '../../../../api/hooks/useApiGet';
import { BoxServer } from '../types';

interface LoadBoxesResponse {
  boxes: BoxServer[];
}

interface LoadBoxesRequest {
  force?: boolean;
  limit?: number;
  offset?: number;
}

interface UseDataResult extends LoadBoxesResponse {
  loading?: boolean;
}

export const useData = (params?: LoadBoxesRequest): UseDataResult => {
  const { data, error, loading } = useApiGet<
    LoadBoxesResponse,
    LoadBoxesRequest
  >({ apiUrl: '/box', params });

  if (error) {
    //TODO
  }

  return { boxes: data?.boxes, loading };
};
