import { useState, useEffect } from 'react';
import throttle from 'lodash/throttle';
import { CellSize, Layout } from '../types';
import {
  ACTIVE_CELL_HEIGHT_MAX,
  ACTIVE_CELL_WIDTH_MAX,
  CELL_HEIGHT_MAX,
  CELL_WIDTH_MAX
} from '../constants';

export const THROTTLE_RESIZE_TIME = 300;

const cellSizeDefault = {
  width: CELL_WIDTH_MAX,
  height: CELL_HEIGHT_MAX
};

export const useLayout = (): Layout => {
  const [layout, setLayout] = useState<Layout>({
    cell: {
      size: cellSizeDefault
    },
    activeCell: {
      size: cellSizeDefault
    }
  });

  useEffect(() => {
    const resize = throttle((): void => {
      const { innerWidth, innerHeight } = window;
      let activeCellWidthFactor = 0.5;
      let activeCellHeightFactor = 0.9;

      if (innerWidth < 480) {
        //Mobile
        activeCellWidthFactor = 0.8;
        activeCellHeightFactor = 0.8;
      }

      setLayout({
        cell: {
          size: {
            width: Math.min(CELL_WIDTH_MAX, innerWidth / 2),
            height: Math.min(CELL_HEIGHT_MAX, innerHeight / 2)
          }
        },
        activeCell: {
          size: {
            width: Math.min(
              ACTIVE_CELL_WIDTH_MAX,
              innerWidth * activeCellWidthFactor
            ),
            height: Math.min(
              ACTIVE_CELL_HEIGHT_MAX,
              innerHeight * activeCellHeightFactor
            )
          }
        }
      });
    }, THROTTLE_RESIZE_TIME);
    resize();
    window.addEventListener('resize', resize);

    return () => window.removeEventListener('resize', resize);
  }, [setLayout]);

  return layout;
};
