import { TeamBgEffect, Theme } from '../types';
import styles from './theme.module.css';
import GridBgTheme from './GridBgTheme/GridBgTheme';

const theme: Theme = {
  GridBgTheme,
  classes: styles,
  themeBgEffects: {
    inEffect: TeamBgEffect.FADE_IN
  },
  mediaToPreload: [
    'https://dl.dropboxusercontent.com/s/dpy1j5ty48b56om/traffic-light.mp4'
  ]
};

export default theme;
