import React, { useRef } from 'react';
import BoxData from '../GridBoxes/BoxData';
import isVideoUrl from '../../../../../global/helpers/isVideoUrl';
import isImageUrl from '../../../../../global/helpers/isImageUrl';
import styles from './ThemeMediaPreload.module.css';
interface ThemeMediaPreloadProps {
  gridBoxes: BoxData[];
}
export default function ThemeMediaPreload({
  gridBoxes
}: ThemeMediaPreloadProps): JSX.Element {
  const imagesToPreloadRef = useRef<Map<string, boolean>>(new Map());
  const videosToPreloadRef = useRef<Map<string, boolean>>(new Map());
  gridBoxes?.forEach((box) => {
    const { mediaToPreload } = box.theme;
    mediaToPreload?.forEach((media) => {
      if (isImageUrl(media) && !imagesToPreloadRef.current.has(media)) {
        imagesToPreloadRef.current.set(media, true);
      } else if (isVideoUrl(media) && !videosToPreloadRef.current.has(media)) {
        videosToPreloadRef.current.set(media, true);
      }
    });
  });
  return (
    <div className={styles.wrapper}>
      {Array.from(imagesToPreloadRef.current.keys()).map((media) => (
        <div
          style={{ backgroundImage: `url(${media})` }}
          key={media}
          className={styles.imageWrapper}
        ></div>
      ))}
      {Array.from(videosToPreloadRef.current.keys()).map((media) => (
        <div className={styles.videoWrapper} key={media}>
          <video src={media} preload="auto" muted></video>
        </div>
      ))}
    </div>
  );
}
