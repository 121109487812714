import React, { useEffect, useState, useRef } from 'react';
import classnames from 'classnames';
import Box from '../Box/Box';
import { useLostFoundContext } from '../../helpers/lostFoundContext';
import withBgTheme from '../../helpers/withBgTheme';
import addIcon from '../../../../assets/add.svg';
import BoxCreateOverlay from '../Box/BoxCreateOverlay/BoxCreateOverlay';
import { useGlobalRequestContext } from '../../../../../global/helpers/globalRequestContext';
import ThemeMediaPreload from '../ThemeMediaPreload/ThemeMediaPreload';
import editIcon from '../../../../assets/edit.svg';
import { GridBoxesProps } from './GridBoxes.types';
import styles from './GridBoxes.module.css';
import { useGridBoxes } from './hooks/useGridBoxes';
import { findBoxByCoords } from './utils';
import { keyToCoords } from './constants';

const GRID_TRANSITION_TIME_SEC = 0.5;

function GridBoxes({
  boxes,
  isLoading,
  onNeedMoreBoxes
}: GridBoxesProps): JSX.Element {
  const { user } = useGlobalRequestContext();
  const { isActiveBoxEdit, setIsActiveBoxEdit } = useLostFoundContext();
  const [isBoxCreateFormOn, setIsBoxCreateFormOn] = useState(false);
  const { gridBoxes, gridMoving, onBoxClick, onGridTransitionEnd, theme } =
    useGridBoxes({
      boxesServer: boxes,
      onNeedMoreBoxes,
      isActiveBoxEdit
    });
  const themeRef = useRef<typeof theme>();
  const GridGbThemeRef = useRef<typeof theme.GridBgTheme>();
  const isMoving = !!gridMoving?.gridShiftPos;
  const gridStyle = {
    '--grid-trans-time': `${GRID_TRANSITION_TIME_SEC}s`,
    translate: `${gridMoving?.gridShiftPos?.left || 0}px ${
      gridMoving?.gridShiftPos?.top || 0
    }px`
  };
  useEffect(() => {
    let timer: ReturnType<typeof setTimeout>;
    if (isMoving) {
      timer = setTimeout(
        () => onGridTransitionEnd(),
        GRID_TRANSITION_TIME_SEC * 1000
      );
    }
    return () => clearTimeout(timer);
  }, [isMoving, onGridTransitionEnd]);

  if (themeRef.current !== theme) {
    themeRef.current = theme;
    //Storing theme in ref to avoid re-rendering of GridBgTheme which may cause an undesired visual effect
    GridGbThemeRef.current = theme?.GridBgTheme;
    if (theme?.themeBgEffects) {
      GridGbThemeRef.current = withBgTheme(theme?.GridBgTheme, { theme });
    }
  }

  useEffect(() => {
    const onKeyUp = (event: KeyboardEvent): void => {
      let nextBox = findBoxByCoords(gridBoxes, keyToCoords[event.key]);
      if (nextBox) {
        onBoxClick(nextBox);
      }
    };
    window.addEventListener('keyup', onKeyUp);
    return () => {
      window.removeEventListener('keyup', onKeyUp);
    };
  }, [gridBoxes, onBoxClick]);

  return (
    <>
      <div className={styles.container}>
        {GridGbThemeRef.current ? <GridGbThemeRef.current /> : null}
        <div
          className={classnames(styles.grid, isMoving && styles.gridMoving)}
          style={gridStyle}
        >
          {isLoading && !gridBoxes?.length ? <div>Loading...</div> : null}
          {gridBoxes?.map((box) => (
            <Box
              box={box}
              isMoving={isMoving}
              onActivate={(): void => onBoxClick(box)}
              theme={theme}
              key={box.data.uid}
            />
          ))}
        </div>
        <ThemeMediaPreload gridBoxes={gridBoxes} />
      </div>
      {user?.name ? (
        <div className={styles.toolbar}>
          <button
            type="button"
            className={styles.toolbarButton}
            onClick={(): void => setIsActiveBoxEdit(true)}
            disabled={isActiveBoxEdit || isBoxCreateFormOn}
          >
            <img src={editIcon} alt="Edit" width="16" height="16" />
          </button>
          <button
            type="button"
            className={styles.toolbarButton}
            onClick={(): void => setIsBoxCreateFormOn(true)}
            disabled={isActiveBoxEdit}
          >
            <img src={addIcon} alt="Add" width="16" height="16" />
          </button>
        </div>
      ) : null}
      {isBoxCreateFormOn ? (
        <BoxCreateOverlay
          onClose={(): void => setIsBoxCreateFormOn(false)}
          onCloseAfterCreate={(): void => {
            setIsBoxCreateFormOn(false);
            location.reload();
          }}
        />
      ) : null}
    </>
  );
}

export default GridBoxes;
