import React, {
  createContext,
  useContext,
  FunctionComponent,
  ReactNode,
  useState
} from 'react';
import { Layout } from '../types';
import { useLayout } from './useLayout';

interface LostFoundContextValue {
  layout?: Layout;
  isActiveBoxEdit?: boolean;
  setIsActiveBoxEdit?: (newState?: boolean) => void;
}
const LostFoundContext = createContext<LostFoundContextValue>({});

interface LostFoundContextProviderProps {
  children?: ReactNode;
}

export const LostFoundContextProvider: FunctionComponent<
  LostFoundContextProviderProps
> = ({ children }) => {
  const layout = useLayout();
  const [isActiveBoxEdit, setIsActiveBoxEdit] = useState(false);
  return (
    <LostFoundContext.Provider
      value={{ layout, isActiveBoxEdit, setIsActiveBoxEdit }}
    >
      {children}
    </LostFoundContext.Provider>
  );
};

export const useLostFoundContext = (): LostFoundContextValue =>
  useContext(LostFoundContext);
