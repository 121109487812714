import React, { ReactElement } from 'react';
import BoxData from '../GridBoxes/BoxData';
import { ContentSizeLevel } from '../../types';
import isVideoUrl from '../../../../../global/helpers/isVideoUrl';
import BoxContentTypeVideo from './contentTypes/BoxContentTypeVideo/BoxContentTypeVideo';
import BoxContentTypeText from './contentTypes/BoxContentTypeText/BoxContentTypeText';
import styles from './Box.module.css';
import { BoxContentTypeProps } from './Box.types';

type Title = BoxData['data']['title'];
type UseBoxContentInput = BoxContentTypeProps;
interface UseBoxContentOutput {
  content: ReactElement;
  boxActiveClassName?: string;
  cellActiveClassName?: string;
  contentActiveClassName?: string;
  contentSizeLevel?: ContentSizeLevel;
}

enum BoxContentType {
  TEXT = 'TEXT',
  VIDEO = 'VIDEO'
}

const getContentType = (title?: Title): BoxContentType => {
  if (isVideoUrl(title)) {
    return BoxContentType.VIDEO;
  }
  return BoxContentType.TEXT;
};

const getContentSizeLevel = (title?: Title): ContentSizeLevel => {
  const length = String(title).length;
  if (length < 100) {
    return ContentSizeLevel.SMALL;
  }
  if (length < 200) {
    return ContentSizeLevel.MEDIUM;
  }
  return ContentSizeLevel.LARGE;
};
export const useBoxContent = (
  props: UseBoxContentInput
): UseBoxContentOutput => {
  const contentType = getContentType(props.title);
  let boxActiveClassName: string = undefined;
  let cellActiveClassName: string = undefined;
  let contentActiveClassName: string = undefined;
  let content: ReactElement;
  let contentSizeLevel: ContentSizeLevel;
  if (contentType === BoxContentType.VIDEO) {
    content = <BoxContentTypeVideo {...props} />;
    cellActiveClassName = styles.activeCellVideo;
    boxActiveClassName = styles.activeBoxVideo;
    contentActiveClassName = styles.activeContentVideo;
  } else if (contentType === BoxContentType.TEXT) {
    content = <BoxContentTypeText {...props} />;
    contentActiveClassName = styles.activeContentText;
    contentSizeLevel = getContentSizeLevel(props.title);
  }
  return {
    content,
    cellActiveClassName,
    boxActiveClassName,
    contentActiveClassName,
    contentSizeLevel
  };
};
