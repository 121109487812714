import { TeamBgEffect, Theme } from '../types';
import styles from './theme.module.css';
import GridBgTheme from './GridBgTheme/GridBgTheme';

const theme: Theme = {
  GridBgTheme: GridBgTheme,
  classes: styles,
  themeBgEffects: {
    inEffect: TeamBgEffect.FADE_IN
  },
  mediaToPreload: [
    'https://dl.dropboxusercontent.com/scl/fi/l26j2d273xe2hrsejc9uf/farm-house-window.jpg?rlkey=7g5086zslb1snutf0lpztv0kj'
  ]
};

export default theme;
