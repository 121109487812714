import { BoxServer } from '../../types';
import { getRandomTheme } from '../../common/themes';
import { Theme } from '../../common/themes/types';

export interface BoxPosition {
  left: number;
  top: number;
}

export interface BoxCoordinates {
  row: number;
  col: number;
}

class BoxData {
  data: BoxServer;
  pos: BoxPosition;
  coords?: BoxCoordinates;
  isActive?: boolean;
  theme?: Theme;

  constructor(data: BoxServer) {
    this.data = { ...data };
    this.theme = getRandomTheme(data.id);
  }
  updateData(newData: BoxServer): void {
    this.data = { ...this.data, ...newData };
  }
}

export default BoxData;
