import { useState } from 'react';
import { apiDelete, ApiError } from '../services/api';
import { isDevEnv } from '../../client/helpers';

interface UseApiDeleteInput {
  apiUrl: string;
}

interface UseApiDeleteResult<Res = any, Req = any> {
  loading?: boolean;
  error?: ApiError<Res, Req>;
  data?: Res;
  execute?: () => void;
}

export default function useApiDelete<Res = any, Req = any>({
  apiUrl
}: UseApiDeleteInput): UseApiDeleteResult<Res, Req> {
  const [data, setData] = useState<Res>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<ApiError>();
  const execute: UseApiDeleteResult<Res, Req>['execute'] = () => {
    setLoading(true);
    apiDelete<Res>(apiUrl)
      .then((res) => {
        setData(res.data);
      })
      .catch((err: ApiError) => {
        if (isDevEnv()) {
          //TODO better handle errors in prod and dev
          console.error(`Error: ${err}`);
        }
        setError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return {
    loading,
    error,
    data,
    execute
  };
}
