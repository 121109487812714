import React, { FunctionComponent, useEffect } from 'react';
import classnames from 'classnames';
import { PublicUser } from '../../../../../global/types';
import useApiPost from '../../../../../api/hooks/useApiPost';
import Button from '../../../../ui/components/Button/Button';
import styles from './UserInfo.module.css';

interface UserInfoProps {
  user: PublicUser;
}

interface LoginRequest {
  name?: string;
}
interface LoginResponse {
  success?: boolean;
}

const UserInfo: FunctionComponent<UserInfoProps> = ({ user }) => {
  const { execute, loading, data } = useApiPost<LoginResponse, LoginRequest>({
    apiUrl: '/logout',
    params: {
      name: user.name
    }
  });
  const onClick = (): void => {
    execute();
  };

  useEffect(() => {
    if (data?.success) {
      location.reload();
    }
  }, [data?.success]);

  return (
    <div className={classnames(styles.box, loading && styles.isLoading)}>
      <div className={styles.content}>
        {user.name} <Button onClick={onClick}>Logout</Button>
      </div>
    </div>
  );
};

export default UserInfo;
