import { Box } from '../../../server/types';
import BoxData from './components/GridBoxes/BoxData';

export type BoxServer = Box;

export interface CellSize {
  width: number;
  height: number;
}

interface CellDesign {
  fontSize: number;
}

interface CellLayout {
  size: CellSize;
  design?: CellDesign;
}

export interface Layout {
  cell: CellLayout;
  activeCell: CellLayout;
}

export interface GridShiftPos {
  left?: number;
  top?: number;
}

export interface GridShiftCoords {
  row: number;
  col: number;
}

export interface GridMoving {
  newActiveBox: BoxData;
  gridShiftPos: GridShiftPos;
  gridShiftCoords: GridShiftCoords;
}

export type OnNeedMoreBoxes = () => void;

export enum ContentSizeLevel {
  SMALL = 1,
  MEDIUM,
  LARGE
}
