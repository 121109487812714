import React, { FunctionComponent, useEffect, useRef } from 'react';
import classnames from 'classnames';
import { BoxContentTypeProps } from '../../Box.types';
import styles from './BoxContentTypeVideo.module.css';

const BoxContentTypeVideo: FunctionComponent<BoxContentTypeProps> = ({
  title,
  isActive
}) => {
  const videoRef = useRef<HTMLVideoElement>();
  useEffect(() => {
    const video = videoRef?.current;
    if (!video) {
      return;
    }
    if (isActive) {
      video.currentTime = 0;
      video.play();
    } else {
      video.currentTime = 1;
      videoRef?.current.pause();
    }
  }, [isActive]);
  //TODO add some controls to pause/mute
  return (
    <div
      className={classnames(
        styles.videoWrapper,
        isActive && styles.videoWrapperActive
      )}
    >
      <video className={styles.video} src={title} ref={videoRef}></video>
    </div>
  );
};

export default BoxContentTypeVideo;
