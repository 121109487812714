import { Theme } from '../types';
import BoxTheme from './BoxTheme/BoxTheme';
import GridBgTheme from './GridBgTheme/GridBgTheme';

const theme: Theme = {
  BoxTheme,
  GridBgTheme
};

export default theme;
