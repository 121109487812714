import { Theme } from '../types';
import styles from './windowSnow.module.css';
import GridBgTheme from './GridBgTheme/GridBgTheme';

const theme: Theme = {
  GridBgTheme,
  classes: styles,
  mediaToPreload: [
    'https://dl.dropboxusercontent.com/s/4w03ltpk2wg0l9y/window-snow.mp4'
  ]
};

export default theme;
