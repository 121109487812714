import React from 'react';
import classnames from 'classnames';
import { ButtonProps } from './Button.types';
import styles from './Button.module.css';

export default function Button({
  className,
  type = 'button',
  variant = 'primary',
  fullWidth,
  children,
  onClick
}: ButtonProps): JSX.Element {
  return (
    <button
      type={type}
      className={classnames(
        styles.button,
        variant === 'textual' && styles.buttonTextual,
        variant === 'textual-destructive' && styles.buttonTextualDestructive,
        fullWidth && styles.buttonFullWidth,
        className
      )}
      onClick={onClick}
    >
      {children}
    </button>
  );
}
