import { TeamBgEffect, Theme } from '../types';
import GridBgTheme from './GridBgTheme/GridBgTheme';
import styles from './theme.module.css';

const theme: Theme = {
  GridBgTheme,
  classes: styles,
  themeBgEffects: { inEffect: TeamBgEffect.FADE_IN_ZOOM_IN },
  passiveBoxThemeEffects: {
    randomRotate: [-5, 5],
    randomScale: [0.2, 0.8]
  },
  mediaToPreload: [
    'https://dl.dropboxusercontent.com/scl/fi/tlc07z1r6e2qmuqecsvyy/wall-bricks-white.jpg?rlkey=cbrzzrz4exu96k6031c0fnnjb'
  ]
};

export default theme;
