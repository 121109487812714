import { GlobalContext, PublicUser } from '../types';
import { useApiGet } from '../../api/hooks/useApiGet';

interface UserResponse {
  user?: PublicUser;
}

interface UserRequest {}

export const useGlobalRequest = (): GlobalContext => {
  const { data } = useApiGet<UserResponse, UserRequest>({
    apiUrl: '/user'
  });

  return {
    user: data?.user
  };
};
