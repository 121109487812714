import React, { FunctionComponent } from 'react';
import { GridBgThemeProps } from '../../types';
import styles from './GridBgTheme.module.css';

const GridBgTheme: FunctionComponent<GridBgThemeProps> = () => {
  return (
    <div className={styles.container}>
      <div className={styles.videoWrapper}>
        <video
          src="https://dl.dropboxusercontent.com/s/4w03ltpk2wg0l9y/window-snow.mp4"
          autoPlay
          loop
          muted
        ></video>
      </div>
    </div>
  );
};

export default GridBgTheme;
