import { useRef } from 'react';
import Typograf from 'typograf';

Typograf.addRule({
  name: 'common/html/nbrExt',
  handler: function (text) {
    return text.replace(/\n\r?/g, '<br/>');
  }
});

const tp = new Typograf({ locale: ['ru', 'en-US'] });
tp.enableRule('common/nbsp/afterNumber');
tp.enableRule('common/html/nbrExt');
const useTextHtml = (text: string): string => {
  const textRef = useRef('');
  const resRef = useRef('');

  if (textRef.current !== text) {
    resRef.current = tp.execute(text);
    textRef.current = text;
  }

  return resRef.current;
};

export default useTextHtml;
