import { isSsr } from '../../client/helpers';
import { ApiResponse } from '../services/api';

type CacheItem<Res> = {
  promise: Promise<ApiResponse<Res>>;
  data?: Res;
};
type Cache<Res> = { [key: string]: CacheItem<Res> };

const SERIALIZED_CACHE_ID = '__API_CHUNK_DATA__';

//Initialising the cache on the client side with the data already loaded during SSR
const cache: Cache<any> = isSsr() ? {} : parseSerializedApiData();

async function handleAllActiveQueries(): Promise<typeof cache> {
  const keys = Object.keys(cache);
  const promises = keys.map((key) => cache[key].promise);
  await Promise.all(promises);
  return cache;
}

function parseSerializedApiData(): typeof cache {
  let data = {};
  if (typeof document.getElementById === 'function') {
    const rawData = document.getElementById(SERIALIZED_CACHE_ID)?.innerText;
    try {
      if (rawData) {
        data = JSON.parse(rawData);
      }
    } catch (e) {}
  }
  return data;
}

export async function getApiDataSerialized(): Promise<string> {
  const data = { ...(await handleAllActiveQueries()) };
  Object.keys(data).forEach((uri) => {
    delete data[uri].promise;
  });
  return `<script id="${SERIALIZED_CACHE_ID}" type="application/json">${JSON.stringify(
    data
  )}</script>`;
}
export const getApiCache = (): typeof cache => cache;

export function clearApiCache(): void {
  Object.keys(cache).forEach((key) => delete cache[key]);
}
