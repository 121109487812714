import { FunctionComponent, ReactNode } from 'react';
import { ContentSizeLevel } from '../../types';

export interface BoxThemeProps {
  children?: ReactNode;
  isActive?: boolean;
  isMoving?: boolean;
  contentClass?: string;
  contentSizeLevel?: ContentSizeLevel;
  theme?: Theme;
}

export interface GridBgThemeProps {}

interface ThemeClasses {
  boxPassiveWrap?: string;
  boxPassiveContent?: string;
  boxActiveWrap?: string;
  boxActiveContent?: string;
  boxActiveContentSizeSmall?: string;
  boxActiveContentSizeMedium?: string;
  boxActiveContentSizeLarge?: string;
}

export enum TeamBgEffect {
  FADE_IN = 'FADE_IN',
  FADE_IN_ZOOM_IN = 'FADE_IN_ZOOM_IN',
  FADE_IN_ZOOM_OUT = 'FADE_IN_ZOOM_OUT',
  FADE_IN_TOP_DOWN = 'FADE_IN_TOP_DOWN',
  FADE_IN_BLUR_IN = 'FADE_IN_BLUR_IN'
}
interface ThemeBgEffects {
  inEffect: TeamBgEffect;
}

interface PassiveBoxThemeEffects {
  randomRotate?: [number, number];
  randomScale?: [number, number];
}
export interface Theme {
  BoxTheme?: FunctionComponent<BoxThemeProps>;
  GridBgTheme?: FunctionComponent<GridBgThemeProps>;
  classes?: ThemeClasses;
  themeBgEffects?: ThemeBgEffects;
  passiveBoxThemeEffects?: PassiveBoxThemeEffects;
  mediaToPreload?: string[];
}
