import axios, { AxiosResponse, AxiosError } from 'axios';
import { isSsr } from '../../client/helpers';

export type ApiResponse<T = any, D = any> = AxiosResponse<T, D>;
export type ApiError<T = any, D = any> = AxiosError<T, D>;

let baseURL: string;

if (isSsr()) {
  baseURL =
    process.env.NODE_ENV === 'development' && process.env.PORT
      ? `${process.env.RAZZLE_API_HOST}:${process.env.PORT}${process.env.RAZZLE_API_BASE_URI}`
      : `${process.env.RAZZLE_API_HOST}${process.env.RAZZLE_API_BASE_URI}`;
} else {
  baseURL = process.env.RAZZLE_API_BASE_URI;
}

const axiosInstance = axios.create({
  baseURL,
  withCredentials: true
});

export const apiGet = axiosInstance.get;

export const apiPost = axiosInstance.post;

export const apiPut = axiosInstance.put;

export const apiDelete = axiosInstance.delete;
