import React, {
  createContext,
  useContext,
  FunctionComponent,
  ReactNode
} from 'react';
import { GlobalContext } from '../types';
import { useGlobalRequest } from './useGlobalRequest';

const GlobalRequestContext = createContext<GlobalContext>({});

interface GlobalRequestContextProviderProps {
  children?: ReactNode;
}

export const GlobalRequestContextProvider: FunctionComponent<
  GlobalRequestContextProviderProps
> = ({ children }) => {
  const globalRequest = useGlobalRequest();
  return (
    <GlobalRequestContext.Provider value={globalRequest}>
      {children}
    </GlobalRequestContext.Provider>
  );
};

export const useGlobalRequestContext = (): GlobalContext =>
  useContext(GlobalRequestContext);
