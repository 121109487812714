import { useRef, useState, useEffect, useCallback } from 'react';
import {
  BoxServer,
  GridMoving,
  GridShiftCoords,
  Layout,
  OnNeedMoreBoxes
} from '../../../types';
import { useLayout } from '../../../helpers/useLayout';
import BoxData from '../BoxData';
import { forEachGrid, handleBox } from '../utils';
import { Theme } from '../../../common/themes/types';

type OnBoxClick = (box: BoxData) => void;
type OnGridTransitionEnd = () => void;

interface UseGridBoxesInput {
  boxesServer: BoxServer[];
  onNeedMoreBoxes?: OnNeedMoreBoxes;
  isActiveBoxEdit?: boolean;
}

interface GridBoxesState {
  gridBoxes: BoxData[];
  gridMoving?: GridMoving;
  theme?: Theme;
}

interface UseGridBoxesOutput extends GridBoxesState {
  onBoxClick: OnBoxClick;
  onGridTransitionEnd: OnGridTransitionEnd;
}

type Grid = BoxData[][];

export function useGridBoxes({
  boxesServer,
  onNeedMoreBoxes,
  isActiveBoxEdit
}: UseGridBoxesInput): UseGridBoxesOutput {
  const boxesCache = useRef(new Map<string, BoxServer>());
  const [storedServerBoxes, setStoredServerBoxes] = useState<BoxServer[]>();
  const gridRef = useRef<Grid>([]);
  const layout = useLayout();
  const [storedLayout, setStoredLayout] = useState<Layout>(layout);
  const [gridBoxesState, setGridBoxesState] = useState<GridBoxesState>();
  const gridMovingRef = useRef<GridMoving>();

  const shiftBoxFromCache = (): BoxServer | undefined => {
    const iter = boxesCache.current.keys();
    const uid = iter.next().value;
    if (uid) {
      const box = boxesCache.current.get(uid);
      boxesCache.current.delete(uid);
      return box;
    }
    return;
  };

  const fillGrid = useCallback(
    (offset?: GridShiftCoords): void => {
      const gridBoxes: BoxData[] = [];
      const newGrid: Grid = [];
      const gridMoving = gridMovingRef.current;
      let numMissingBoxes = 0;
      let activeBox: BoxData = undefined;

      forEachGrid((coords) => {
        if (!newGrid[coords.row]) {
          newGrid[coords.row] = [];
        }
        const relatedPosInOldGrid: GridShiftCoords = {
          row: coords.row + (offset?.row || 0),
          col: coords.col + (offset?.col || 0)
        };
        let box =
          //First, reusing the box in the same (or shifted) position if there is one
          gridRef.current[relatedPosInOldGrid.row]?.[relatedPosInOldGrid.col] ||
          //...if not, looking for one in cache
          shiftBoxFromCache();
        const handledBoxData = handleBox({
          coords,
          layout,
          gridMoving,
          box
        });
        if (handledBoxData) {
          newGrid[coords.row][coords.col] = handledBoxData;
          gridBoxes.push(handledBoxData);
          if (handledBoxData.isActive) {
            activeBox = handledBoxData;
          }
        } else {
          numMissingBoxes++;
        }
      });
      gridRef.current = newGrid;
      setGridBoxesState({
        gridMoving,
        gridBoxes,
        theme: activeBox?.theme
      });
      if (numMissingBoxes) {
        onNeedMoreBoxes();
      }
    },
    [layout, onNeedMoreBoxes]
  );

  const onBoxClick: OnBoxClick = (box) => {
    if (gridMovingRef.current) {
      //Prevent another moving, when one is in progress
      return;
    }
    if (isActiveBoxEdit) {
      //Prevent moving while editing is in progress
      return;
    }
    gridMovingRef.current = {
      newActiveBox: box,
      gridShiftPos: {
        left: -1 * box.coords.col * layout.cell.size.width,
        top: -1 * box.coords.row * layout.cell.size.height
      },
      gridShiftCoords: box.coords
    };
    fillGrid();
  };

  const onGridTransitionEnd: OnGridTransitionEnd = () => {
    if (gridMovingRef.current) {
      const offset = { ...gridMovingRef.current?.gridShiftCoords };
      gridMovingRef.current = undefined;
      fillGrid(offset);
    }
  };

  //This is to render boxes initially during SSR or after new boxes have been loaded
  if (boxesServer && boxesServer !== storedServerBoxes) {
    const boxesToDispatch = [...(boxesServer || [])];
    boxesToDispatch.forEach((box) => {
      if (!boxesCache.current.has(box.uid)) {
        boxesCache.current.set(box.uid, box);
      }
    });
    fillGrid();
    setStoredServerBoxes(boxesServer);
  }

  //This is to render boxes during CSR on window resize
  // (if cell size has actually changed)
  useEffect(() => {
    if (
      !storedLayout ||
      layout.cell.size.width !== storedLayout.cell.size.width ||
      layout.cell.size.height !== layout.cell.size.height
    ) {
      fillGrid();
      setStoredLayout(layout);
    }
  }, [layout, fillGrid, storedLayout]);

  return {
    ...gridBoxesState,
    onBoxClick,
    onGridTransitionEnd
  };
}
