import { TeamBgEffect, Theme } from '../types';
import styles from './theme.module.css';
import GridBgTheme from './GridBgTheme/GridBgTheme';

const theme: Theme = {
  GridBgTheme: GridBgTheme,
  classes: styles,
  themeBgEffects: {
    inEffect: TeamBgEffect.FADE_IN_BLUR_IN
  },
  mediaToPreload: [
    'https://dl.dropboxusercontent.com/scl/fi/oq3ocywyl3jchyfdx04gy/dark-lake.jpg?rlkey=90s7cgm7wpn0spytr9n3pnd2w'
  ]
};

export default theme;
