import React, { ReactNode } from 'react';
import classnames from 'classnames';
import { TeamBgEffect, Theme } from '../../common/themes/types';
import styles from './GridBgThemeWrapper.module.css';

interface GridBgThemeWrapperProps {
  theme: Theme;
  children?: ReactNode;
}

const classesPerEffect = {
  [TeamBgEffect.FADE_IN]: styles.fadeIn,
  [TeamBgEffect.FADE_IN_ZOOM_IN]: styles.fadeInZoomIn,
  [TeamBgEffect.FADE_IN_ZOOM_OUT]: styles.fadeInZoomOut,
  [TeamBgEffect.FADE_IN_TOP_DOWN]: styles.fadeInTopDown,
  [TeamBgEffect.FADE_IN_BLUR_IN]: styles.fadeInBlurIn
};
function GridBgThemeWrapper({
  theme,
  children
}: GridBgThemeWrapperProps): JSX.Element {
  return (
    <div
      className={classnames(
        styles.wrapper,
        theme.themeBgEffects?.inEffect
          ? classesPerEffect[theme.themeBgEffects.inEffect]
          : undefined
      )}
    >
      {children}
    </div>
  );
}

export default GridBgThemeWrapper;
