import { useState } from 'react';
import qs from 'qs';
import { ApiError, apiGet } from '../services/api';
import { getApiCache } from '../apiCache';
import { isDevEnv, isSsr } from '../../client/helpers';
import globalInitRequestData from '../../global/helpers/globalInitRequestData';
import { HTTP_HEADER_SSID } from '../../global/constants';

interface UseApiGetInput<Req = any> {
  apiUrl: string;
  params?: Req;
}

interface UseApiGetResult<Res = any, Req = any> {
  loading?: boolean;
  error?: ApiError<Res, Req>;
  data?: Res;
}

export function useApiGet<Res = any, Req = any>({
  apiUrl,
  params
}: UseApiGetInput<Req>): UseApiGetResult<Res, Req> {
  const initRequestData = globalInitRequestData.getData();
  const [data, setData] = useState<Res>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<ApiError>();
  const key = `${apiUrl}:${qs.stringify(params)}`;
  const cache = getApiCache();

  let cacheItem = cache[key];
  if (!cacheItem) {
    setLoading(true);
    const promise = apiGet<Res>(apiUrl, {
      params,
      headers:
        //Requests made during SSR doesn't send any cookies, so things based on a session won't work (e.g. user authentication status)
        // that's why we receive it from the server as `ssid` and send back as a header
        isSsr() && initRequestData.ssid
          ? { [HTTP_HEADER_SSID]: initRequestData.ssid }
          : undefined
    });
    //Creating a new cache entry for previously unknown request
    cacheItem = cache[key] = { promise };
    promise
      .then((res) => {
        cacheItem.data = res.data;
        setData(res.data);
      })
      .catch((err: ApiError) => {
        if (isDevEnv()) {
          //TODO better handle errors in prod and dev
          console.error(`Error: ${err.message}`);
        }
        setError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  } else if (cacheItem.data && cacheItem.data !== data) {
    setData(cacheItem.data);
  }
  return {
    data,
    loading,
    error
  };
}
