import React, { FunctionComponent, useEffect, useState } from 'react';
import classnames from 'classnames';
import { BoxThemeProps } from '../../types';
import { ContentSizeLevel } from '../../../../types';
import styles from './BoxTheme.module.css';

type Style = Record<string, string | number>;

const classesPerSizeLevel = {
  [ContentSizeLevel.SMALL]: styles.boxActiveContentSizeSmall,
  [ContentSizeLevel.MEDIUM]: styles.boxActiveContentSizeMedium
};

const BoxTheme: FunctionComponent<BoxThemeProps> = ({
  children,
  isActive,
  isMoving,
  contentClass,
  contentSizeLevel,
  theme
}) => {
  const themeClassesPerSizeLevel = {
    [ContentSizeLevel.SMALL]: theme.classes?.boxActiveContentSizeSmall,
    [ContentSizeLevel.MEDIUM]: theme.classes?.boxActiveContentSizeMedium
  };

  const [boxStyle, setBoxStyle] = useState<Style>({});

  useEffect(() => {
    let newStyle: Style = {};
    if (!isActive && !isMoving) {
      if (theme.passiveBoxThemeEffects?.randomRotate) {
        const [min, max] = theme.passiveBoxThemeEffects.randomRotate;
        const rotate = Math.floor(Math.random() * (max - min + 1) + min);
        newStyle['--box-rotate'] = `${rotate}deg`;
      }
      if (theme.passiveBoxThemeEffects?.randomScale) {
        const [min, max] = theme.passiveBoxThemeEffects.randomScale;
        newStyle['--box-scale'] = Math.random() * (max - min) + min;
      }
    }
    setBoxStyle(newStyle);
  }, [
    isActive,
    isMoving,
    theme.passiveBoxThemeEffects?.randomRotate,
    theme.passiveBoxThemeEffects?.randomScale
  ]);

  return (
    <div
      className={classnames(
        isActive ? styles.boxActiveWrap : styles.boxPassiveWrap,
        isActive ? theme.classes?.boxActiveWrap : theme.classes?.boxPassiveWrap
      )}
      style={boxStyle}
    >
      <div
        className={classnames(
          isActive
            ? theme.classes?.boxActiveContent
            : theme.classes?.boxPassiveContent,
          isActive &&
            contentSizeLevel &&
            themeClassesPerSizeLevel[contentSizeLevel]
            ? themeClassesPerSizeLevel[contentSizeLevel]
            : undefined,
          contentClass,
          isActive ? styles.boxActiveContent : styles.boxPassiveContent,
          isActive && contentSizeLevel && classesPerSizeLevel[contentSizeLevel]
            ? classesPerSizeLevel[contentSizeLevel]
            : undefined
        )}
      >
        {children}
      </div>
    </div>
  );
};

export default BoxTheme;
