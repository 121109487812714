import React, {
  useState,
  useEffect,
  useRef,
  FormEvent,
  ChangeEvent
} from 'react';
import classnames from 'classnames';
import Button from '../../../../../ui/components/Button/Button';
import { BoxFormProps } from './BoxForm.types';
import styles from './BoxForm.module.css';

export default function BoxForm({
  data,
  loading,
  formClassName,
  isDeletable,
  errorMsg,
  onChange,
  onSubmit,
  onCancel,
  onDelete
}: BoxFormProps): JSX.Element {
  const [state, setState] = useState(data);
  const [isDeleting, setIsDeleting] = useState(false);
  const textareaRef = useRef<HTMLTextAreaElement>();

  if (state !== data) {
    setState(data);
  }
  const onChangeHandler = (e: ChangeEvent<HTMLTextAreaElement>): void => {
    const { name, value } = e.target;
    const newState = { ...state, [name]: value };
    setState(newState);
    onChange?.(newState);
  };
  useEffect(() => {
    textareaRef.current?.focus();
  }, []);
  const onSubmitHandler = (e: FormEvent): void => {
    e.preventDefault();
    onSubmit?.();
  };

  return (
    <form
      className={classnames(
        styles.form,
        formClassName,
        loading && styles.isLoading
      )}
      onSubmit={onSubmitHandler}
    >
      <fieldset className={styles.fieldset}>
        <textarea
          name="title"
          onChange={onChangeHandler}
          value={state.title}
          className={classnames(styles.textarea)}
          ref={textareaRef}
        />
      </fieldset>
      {errorMsg ? (
        <div className={classnames(styles.fieldset, styles.fieldsetError)}>
          {errorMsg}
        </div>
      ) : null}
      <fieldset className={classnames(styles.fieldset, styles.fieldsetToolbar)}>
        <div className={styles.buttonsGroup}>
          <Button type="submit">Save box</Button>
          <Button onClick={onCancel} variant="textual">
            Cancel
          </Button>
        </div>
        {isDeletable ? (
          <div className={styles.buttonsGroup}>
            {isDeleting ? (
              <>
                <Button variant="textual-destructive" onClick={onDelete}>
                  Delete for real
                </Button>
                <Button
                  variant="textual"
                  onClick={(): void => setIsDeleting(false)}
                >
                  No, keep it
                </Button>
              </>
            ) : (
              <Button
                variant="textual-destructive"
                onClick={(): void => setIsDeleting(true)}
              >
                Delete
              </Button>
            )}
          </div>
        ) : null}
      </fieldset>
    </form>
  );
}
