import { InitRequestData } from '../types';

class GlobalInitRequestData {
  data: InitRequestData;
  constructor() {}
  getData(): InitRequestData {
    return this.data;
  }
  setData(data: InitRequestData): void {
    this.data = { ...data };
  }
}

export default new GlobalInitRequestData();
