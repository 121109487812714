import React, { FunctionComponent, useState, useEffect } from 'react';
import classnames from 'classnames';
import useApiPost from '../../../../../api/hooks/useApiPost';
import { PublicUser } from '../../../../../global/types';
import Button from '../../../../ui/components/Button/Button';
import styles from './SignInForm.module.css';

interface SignInFormState {
  name: string;
  password: string;
}

interface SignInResponse {
  user?: PublicUser;
}

type SignInRequest = SignInFormState;

const SignInForm: FunctionComponent = () => {
  const [state, setState] = useState<SignInFormState>({
    name: '',
    password: ''
  });
  const { execute, loading, data } = useApiPost<SignInResponse, SignInRequest>({
    apiUrl: '/login',
    params: {
      username: state.name,
      password: state.password
    }
  });
  const onSubmit = (e): void => {
    execute();
    e.preventDefault();
  };
  const onInputChange = (e): void => {
    const { name, value } = e.target;
    setState({
      ...state,
      [name]: value
    });
  };
  useEffect(() => {
    if (data?.user?.name) {
      location.reload();
    }
  }, [data?.user?.name]);
  return (
    <form
      onSubmit={onSubmit}
      className={classnames(styles.form, loading && styles.isLoading)}
    >
      <fieldset className={styles.fieldset}>
        <label className={styles.field}>
          <input
            type="text"
            name="name"
            value={state.name}
            onChange={onInputChange}
            className={styles.input}
          />
        </label>
        <label className={styles.field}>
          <input
            type="password"
            name="password"
            value={state.password}
            onChange={onInputChange}
            className={styles.input}
          />
        </label>
      </fieldset>
      <Button type="submit" fullWidth>
        Sign in
      </Button>
    </form>
  );
};

export default SignInForm;
