import BoxData, { BoxCoordinates, BoxPosition } from '../BoxData';
import { GRID_COLS_RADIUS, GRID_ROWS_RADIUS } from '../../../constants';
import { BoxServer, CellSize, GridMoving, Layout } from '../../../types';

export const forEachGrid = (
  callback: (coords: BoxCoordinates) => void
): void => {
  const iterateRow = (row: number): void => {
    callback({ row, col: 0 });
    for (let col = 1; col <= GRID_COLS_RADIUS; col++) {
      callback({ row, col: -col });
      callback({ row, col: col });
    }
  };
  iterateRow(0);
  for (let row = 1; row <= GRID_ROWS_RADIUS; row++) {
    iterateRow(-row);
    iterateRow(row);
  }
};

export const getBoxPosByCoordinates = (
  coords: BoxCoordinates,
  cellSize: CellSize
): BoxPosition => {
  return {
    left: coords.col * cellSize.width,
    top: coords.row * cellSize.height
  };
};

type HandleBoxProps = {
  coords: BoxCoordinates;
  layout: Layout;
  gridMoving?: GridMoving;
  box?: BoxServer | BoxData;
};

const isBoxData = (box: BoxServer | BoxData): box is BoxData =>
  !!(box as BoxData).data?.uid;

export const handleBox = ({
  coords,
  layout,
  gridMoving,
  box
}: HandleBoxProps): BoxData | undefined => {
  if (!box) {
    return;
  }
  const boxData = isBoxData(box) ? box : new BoxData(box);
  boxData.pos = getBoxPosByCoordinates(coords, layout.cell.size);
  boxData.coords = coords;
  if (gridMoving?.newActiveBox) {
    boxData.isActive = boxData.data.uid === gridMoving.newActiveBox.data.uid;
  } else {
    boxData.isActive = coords.row === 0 && coords.col === 0;
  }
  return boxData;
};

export const findBoxByCoords = (
  boxes: BoxData[],
  coords: BoxCoordinates | undefined
): BoxData | undefined =>
  coords
    ? boxes.find(
        (box) => box.coords.col === coords.col && box.coords.row === coords.row
      )
    : undefined;
