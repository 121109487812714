import { useState } from 'react';
import { ApiError, apiPut } from '../services/api';
import { isDevEnv } from '../../client/helpers';

interface UseApiPutInput<Req = any> {
  apiUrl: string;
  params?: Req;
}

interface UseApiPutResult<Res = any, Req = any> {
  loading?: boolean;
  error?: ApiError<Res, Req>;
  data?: Res;
  execute?: () => void;
}

export default function useApiPut<Res = any, Req = any>({
  apiUrl,
  params
}: UseApiPutInput): UseApiPutResult<Res, Req> {
  const [data, setData] = useState<Res>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<ApiError>();
  const execute: UseApiPutResult<Res, Req>['execute'] = () => {
    setLoading(true);
    apiPut<Res>(apiUrl, params)
      .then((res) => {
        setData(res.data);
      })
      .catch((err: ApiError) => {
        if (isDevEnv()) {
          //TODO better handle errors in prod and dev
          console.error(`Error: ${err}`);
        }
        setError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return {
    loading,
    error,
    data,
    execute
  };
}
