import React, { FunctionComponent } from 'react';
import { Theme } from '../common/themes/types';
import GridBgThemeWrapper from '../components/GridBgThemeWrapper/GridBgThemeWrapper';
const withBgTheme = (
  WrappedComponent: FunctionComponent,
  { theme }: { theme: Theme }
): FunctionComponent => {
  const ComponentWithTheme: FunctionComponent = (props) => {
    return (
      <GridBgThemeWrapper theme={theme}>
        <WrappedComponent {...props} />
      </GridBgThemeWrapper>
    );
  };

  return ComponentWithTheme;
};

export default withBgTheme;
