import { Theme } from './types';
import dark from './dark';
import basic from './basic';
import windowSnow from './windowSnow';
import bricks from './bricks';
import dive from './dive';
import windowBars from './windowBars';
import rain from './rain';
import waves from './waves';
import trafficLight from './trafficLight';

const themes = [
  dive,
  dark,
  windowSnow,
  bricks,
  windowBars,
  rain,
  waves,
  trafficLight
];

export const getRandomTheme = (id: number): Theme => {
  //"Random" index needs to be based on a box ID in order for the theme to match on server and client
  let index = Number(id) % themes.length;

  // index = themes.length - 1; //Tmp override for dev

  return {
    BoxTheme: basic.BoxTheme,
    GridBgTheme: basic.GridBgTheme,
    ...themes[index]
  };
};
