import React, { FunctionComponent } from 'react';
import { BoxContentTypeProps } from '../../Box.types';
import useTextHtml from '../../../../../../helpers/useTextHtml';

const BoxContentTypeText: FunctionComponent<BoxContentTypeProps> = ({
  title
}) => {
  const contentHtml = useTextHtml(title);
  return <div dangerouslySetInnerHTML={{ __html: contentHtml }}></div>;
};

export default BoxContentTypeText;
