import React, { FunctionComponent, useState } from 'react';
import { useData } from './data/useData';
import GridBoxes from './components/GridBoxes/GridBoxes';
import { LostFoundContextProvider } from './helpers/lostFoundContext';
import { BOXES_LOAD_LIMIT } from './constants';

const LostFound: FunctionComponent = () => {
  const [offset, setOffset] = useState(0);
  const { boxes, loading } = useData({ limit: BOXES_LOAD_LIMIT, offset });

  return (
    <LostFoundContextProvider>
      <GridBoxes
        boxes={boxes}
        isLoading={loading}
        onNeedMoreBoxes={(): void => setOffset(offset + BOXES_LOAD_LIMIT)}
      />
    </LostFoundContextProvider>
  );
};

export default LostFound;
